import React, { useContext, useEffect } from 'react'

//styles
import './NavBar.css'

//icons
import { BankOutlined, BookOutlined, EnvironmentOutlined, HomeOutlined, LaptopOutlined, PoweroffOutlined, TagOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { Route } from '../../Models/Route';

//routes
import { supplierRoutes, organizationRoutes, resellerRoutes, siteRoutes, nonRoutes } from '../../Routes/Routes';
import NavBarItem from './NavBarItem';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { BsBell } from 'react-icons/bs';

const NavBar = () => {
    const [routes, setRoutes] = React.useState<Route[]>(organizationRoutes);

    const {sesion, sendSesion } = useContext(PromiseSesionContext);


    useEffect(() => {
        switch (sesion.context.rol) {
            case "Proveedor":
                setRoutes(supplierRoutes)
                break;
            case "Reseller":
                setRoutes(resellerRoutes)
                break;
            case "Organizacion":
                setRoutes(organizationRoutes)
                break;
            case "Sucursal":
                setRoutes(siteRoutes)
                break;
            default:
                setRoutes(nonRoutes)
                break;
        }
      
    }, [sesion.context.rol]);

    const navigate = useNavigate();

    const closeSession = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("expired")


        sendSesion("UNAUTHORIZED");
        navigate("/Login");

    }

    const toNotifications = () => {
        navigate("/Notifications");

    }
    
  return (
    <div className='NavBar-Component'>
        <div>
        <div className='NavBar-Logo'>
            <img src='/image/logo2.png' ></img>
        </div>

        <div className='NavBar-Links' >
            {routes.map((route:Route, index:number)=>{
                if(!route.navbarShow) return <></>
                return <NavBarItem route={route} key={index}/>
            })}
                    
        </div>
        </div>

        <div>
            {/* <div className='NavBar-Bell' onClick={toNotifications}>
                <BsBell size={18} />
            </div> */}

            <div className='NavBar-Close' onClick={closeSession}>
                <PoweroffOutlined />
            </div>
        </div>



    </div>
  )
}

export default NavBar
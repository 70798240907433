import React, { useContext, useEffect } from 'react'
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import { PromiseSesionContext } from '../Machines/SesionMachine';
import { Button, message, notification, Space } from 'antd';


interface Props {
    children: React.ReactNode
}

const ErrorHandler = ({children}:Props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const {error, sendError} = useContext(PromiseSesionContext);

    const dropError = () => {
        messageApi.open({
            type: error.context.type,
            content: error.context.message,
          });
    }

    useEffect(() => {
        if(error.value==="error"||error.value==="info"){
            dropError()
        }
    }, [error.value]);


  return (
    <>
        {contextHolder}
        

        {children}
    </>
  )
}

export default ErrorHandler
import React, { useContext, useEffect } from 'react'

//styles
import './NavBar.css'
import { nonRoutes, organizationRoutes, resellerRoutes, siteRoutes, supplierRoutes } from '../../Routes/Routes';
import { Route } from '../../Models/Route';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { useNavigate } from 'react-router-dom';
import NavBarItem from './NavBarItem';
import NavbarMovilItem from './NavbarMovilItem';
import { PoweroffOutlined } from '@ant-design/icons';

const NavbarMovil = () => {
    const [routes, setRoutes] = React.useState<Route[]>(organizationRoutes);

    const {sesion, sendSesion } = useContext(PromiseSesionContext);

    useEffect(() => {
        switch (sesion.context.rol) {
            case "Proveedor":
                setRoutes(supplierRoutes)
                break;
            case "Reseller":
                setRoutes(resellerRoutes)
                break;
            case "Organizacion":
                setRoutes(organizationRoutes)
                break;
            case "Sucursal":
                setRoutes(siteRoutes)
                break;
            default:
                setRoutes(nonRoutes)
                break;
        }
      
    }, [sesion.context.rol]);

    const navigate = useNavigate();
    
    const closeSession = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("expired")

        sendSesion("UNAUTHORIZED");
        navigate("/Login");

    }
    
  return (
    <div className='NavbarMovil-component'>
        <div className='NavBar-Links-movil' >
            {routes.map((route:Route, index:number)=>{
                if(!route.navbarShow) return <></>
                return <NavbarMovilItem route={route} key={index}/>
            })}
            <div className='NavBar-Close-movil' onClick={closeSession}>
                <PoweroffOutlined />
            </div>   
        </div>


    </div>
  )
}

export default NavbarMovil